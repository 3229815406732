import React, { Component } from 'react';

export class OnePagerBottom extends Component {
  render() {
    return (
      <div id="OnePagerTwo" className="pagerW">
        <div className="white-text" style={{ height: '100px' }}>
          <p style={{ position: 'relative', top: '35px', textAlign: 'center' }}>FAQ</p>
        </div>

        <div className="container">
          <div className="gray-black-text row" style={{ margin: '50px 40px 50px 40px' }}>
            <div className="col-md-6">
              <details>
                <summary>Which file format can I import?</summary>
                <p>
                  You can import all .vsdx files into Symbio. You can<br />
                  simply convert files with the, .vsd extension yourself<br />
                  and upload them afterwards as well.
                </p>
              </details>

              <details>
                <summary>Do I need to register to use it?</summary>
                <p>
                  To use the Visio® importer, you only need to log in using<br />
                  the same account used for logging into Symbio.
                </p>
              </details>

              <details>
                <summary>In Symbio, I am only a viewer.<br />Can I still do the import?</summary>
                <p>
                  The role does not affect the import. The admin<br />
                  receives a token that he can pass on to anyone in the company<br />
                  The token can be used indefinitely. Thus, any user<br />
                  take over the import regardless of the role.
                </p>
              </details>
            </div>

            <div className="col-md-6">
              <details>
                <summary>Do I need to download anything?</summary>
                <p>
                  Like Symbio, the Visio® Importer is completely web-based.<br />
                  You do not need to download anything to use it.
                </p>
              </details>

              <details>
                <summary>How many files can I import at once?</summary>
                <p>
                  You can import an unlimited number of files at the same time.
                </p>
              </details>

              <details>
                <summary>What will it cost me to use the feature?</summary>
                <p>
                  The Visio® Importer is currently in the beta phase.<br />
                  Therefore, the use is currently still free of charge. In the future, the<br />
                  function will be subject to a fee.
                </p>
              </details>

              <details>
                <summary>How can I convert .vsd files?</summary>
                <p>
                  Open the file in Visio and simply save it using<br />
                  "Save As" with the .vsdx extension.
                </p>
              </details>
            </div>
          </div>
        </div>

        <div className="white-text" style={{ height: '1px' }}></div>

        <div className="container" style={{ display: 'grid', alignItems: 'center' }}>
          <div className="row" style={{ margin: '0 50px 0 50px' }}>
            <div className="col-2" style={{ display: 'flex', flexDirection: 'column' }}>
              <img src="Symbio_Logo_Blau.png" naturalheight="33" naturalwidth="146" style={{ width: "1.2166in", height: "0.275in", marginTop: "5px" }} width="117" height="26" border="0"></img>
            </div>
            <div className="col-2" style={{ display: 'flex', flexDirection: 'column' }}>
              <a href="https://www.symbioworld.com/en/disclaimer/" style={{ color: '#106ebe' }}>Disclaimer</a>
              <a href="https://www.symbioworld.com/en/privacy-policy/" style={{ color: '#106ebe', float: 'right' }}>Privacy Policy</a>
              <a href="https://www.symbioworld.com/en/service/support/" style={{ color: '#106ebe' }}>Support</a>
            </div>
            <div className="col-2" style={{ display: 'flex', flexDirection: 'column' }}>
              <a href="https://www.symbioworld.com/en/resources/downloads/" style={{ color: '#106ebe' }}>Ressources</a>
              <a href="https://www.symbioworld.com/en/resources/release-notes/" style={{ color: '#106ebe' }}>Release notes</a>
              <a href="https://docs.symbioworld.com/admin" style={{ color: '#106ebe' }}>Admin Documentation</a>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    );
  }
}
