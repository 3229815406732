import React, { Component } from 'react';
import { MenuBar } from './MenuBar.js';
import { FileUpload } from './FileUpload.js';
import { PrimaryButton, IconButton } from '@fluentui/react';

export class Mapping extends Component {
  static displayName = Mapping.name;

  allLines = [];
  currentLine = null;
  duplicateShapes = {};
  mappingRows = [];
  scrollY = 0;
  mouseMoved = false;
  currentMasters = [];
  currentSymbioNames = [];
  initialLines = [];
  initialMasters = [];

  constructor(props) {
    // HACK HACK HACK: This is horrible but let's the parent call a function of the child
    props.finalize[0] = (after) => {
      this.endMapping(after);
    };
    props.finalize[1] = (hidden) => {
      this.setState({ componentHidden: hidden });
      setTimeout(() => {
        if (this.refs.canvas == null)
          return;

        // Make the canvas use the full size. This doesn't work using css
        var context = this.refs.canvas.getContext("2d");
        context.canvas.width = 685; // TODO
        context.canvas.height = this.state.canvasHeight;
        this.redrawLines(this.refs.canvas, context);
      }, 0);
    };

    super(props);

    // This binding is necessary to make `this` work in the callback
    this.drawMapping = this.drawMapping.bind(this);
    this.drawLineToMouse = this.drawLineToMouse.bind(this);
    this.drawLineOnScroll = this.drawLineOnScroll.bind(this);
    this.clearConnections = this.clearConnections.bind(this);
    this.resetConnections = this.resetConnections.bind(this);
    this.exportMapping = this.exportMapping.bind(this);
    this.importMapping = this.importMapping.bind(this);
    this.openMappingImport = this.openMappingImport.bind(this);
    this.generateMappingForMasters = this.generateMappingForMasters.bind(this);

    var mapping = this.props.mapping;
    this.currentSymbioNames = mapping.SymbioNames;

    var eliminateDuplicates = FileUpload.mergeShapes;
    var mappingWasSuggested = false;
    for (var i = 0; i < mapping.VisioMasters.length; i++) {
      var master = mapping.VisioMasters[i];

      if (master.MappingPartner == null)
        master.MappingPartner = "";

      var eliminated = false;
      if (this.duplicateShapes[master.DisplayName] == null) {
        this.duplicateShapes[master.DisplayName] = [];
        this.duplicateShapes[master.DisplayName].push(master);
      } else {
        this.duplicateShapes[master.DisplayName].push(master);
        console.debug("Duplicate: " + master.DisplayName + " with " + master.InternalName);

        // If a duplicate has a stencil mapping, don't throw that away
        var firstDuplicate = this.duplicateShapes[master.DisplayName][0];
        if (master.MappingPartner !== "" && firstDuplicate.MappingPartner === "")
          firstDuplicate.MappingPartner = master.MappingPartner;

        if (eliminateDuplicates)
          eliminated = true;
      }

      if (!eliminated) {
        // HACK: We always want "No Master Shape" at the bottom
        master.sortLast = master.internalName === "NoMaster";

        if (master.MappingPartner !== "")
          mappingWasSuggested = true;

        this.currentMasters.push(master);
      }
    }

    // No need to sort if we didn't suggest a mapping (already sorted)
    // Otherwise sort connected last and unconnected first
    if (mappingWasSuggested) {
      this.currentMasters.sort((a, b) => {
        if (a.sortLast)
          return b.sortLast ? 0 : 1;

        if (b.sortLast)
          return -1;

        if (a.MappingPartner !== "")
          return b.MappingPartner !== "" ? 0 : 1;

        return b.MappingPartner !== "" ? -1 : 0;
      });
    }

    var maxIndex = Math.max(mapping.SymbioNames.length, this.currentMasters.length);
    for (/*var*/ i = 0; i < maxIndex; i++) {
      var lFig = null;
      var lButton = null;
      var lRemove = null;
      var rButton = null;
      var rRemove = null;
      var rImg = null;
      var column = [];

      if (i < this.currentMasters.length) {
        var lId = "left" + i;
        var lImg = React.createElement("img", { src: ("data: image/png; base64, " + this.currentMasters[i].Icon) });
        var lCap = React.createElement("p", {}, this.currentMasters[i].DisplayName);
        lFig = React.createElement("div", { className: "mappingFigure", id: ("figure" + i) }, lImg, lCap);

        lRemove = <IconButton
          id={ "rem" + lId}
          styles={{ root: { position: "absolute", top: "35px", left: 0, scale: "50%" } }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Remove mapping"
          onClick={e => { this.removeLineClicked(e) }}
        />;
        lButton = React.createElement("button", { className: "button canvas-button", id: lId, style: { marginTop: '40px' }, onClick: this.drawMapping });
      }

      if (i < mapping.SymbioNames.length) {
        var rId = "right" + i;
        rRemove = <IconButton
          id={ "rem" + rId }
          styles={{ root: { position: "absolute", top: "35px", right: 0, scale: "50%" } }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Remove mapping"
          onClick={e => { this.removeLineClicked(e) }}
        />;
        rButton = React.createElement("button", { className: "button canvas-button float-right", id: rId, style: { marginTop: '40px' }, onClick: this.drawMapping });
        rImg = React.createElement("img", { className: "img-responsive", style: { margin: '20px' }, src: ("data: image/png; base64, " + mapping.SymbioImages[i]) });
      }

      column.push(React.createElement("div", { className: "col-md-3", key: ("ldiv" + i) }, lFig));
      column.push(React.createElement("div", { className: "col-md-6 mapping-row", key: ("mdiv" + i) }, lRemove, lButton, rButton, rRemove));
      column.push(React.createElement("div", { className: "col-md-3", key: ("rdiv" + i) }, rImg));

      this.mappingRows.push(React.createElement("div", { key: ("row" + i), className: "row col-md-12 col-xs-12", id: ("row" + i) }, column));
    }

    this.state = {
      canvasHeight: (Math.max(mapping.SymbioNames.length, this.currentMasters.length) * 100 + 100),
      componentHidden: false,
      status: ""
    };
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.drawLineToMouse, false);
    document.addEventListener("scroll", this.drawLineOnScroll, false);

    // Make the canvas use the full size. This doesn't work using css
    var context = this.refs.canvas.getContext("2d");
    context.canvas.width = 685; // TODO
    context.canvas.height = this.state.canvasHeight;

    // HACK: Let the DOM refresh before adding lines for existing mappings
    setTimeout(() => {
      this.generateMappingForMasters();

      // Create deep clones
      this.initialLines = JSON.parse(JSON.stringify(this.allLines));
      this.initialMasters = JSON.parse(JSON.stringify(this.currentMasters));

      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.disableContinueButton(this.allLines.length < 1);
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.drawLineToMouse, false);
    document.removeEventListener("scroll", this.drawLineOnScroll, false);
  }

  generateMappingForMasters = () => {
    this.allLines = [];

    for (var i = 0; i < this.currentMasters.length; i++) {
      if (this.currentMasters[i].MappingPartner === "")
        continue;

      var symbioIndex = this.currentSymbioNames.indexOf(this.currentMasters[i].MappingPartner);
      if (symbioIndex < 0)
        continue;

      var canvasRect = this.refs.canvas.getBoundingClientRect();
      var leftRect = document.getElementById("left" + i).getClientRects()[0];
      var sX = leftRect.left - canvasRect.left + leftRect.width / 2;
      var sY = leftRect.top - canvasRect.top + leftRect.height / 2;
      var rightRect = document.getElementById("right" + symbioIndex).getClientRects()[0];
      var eX = rightRect.left - canvasRect.left + rightRect.width / 2;
      var eY = rightRect.top - canvasRect.top + rightRect.height / 2;
      this.allLines.push({ start: [sX, sY], end: [eX, eY], startId: ("left" + i), endId: ("right" + symbioIndex) });
    }

    var context = this.refs.canvas.getContext("2d");
    this.redrawLines(this.refs.canvas, context);
  }

  drawMapping(event) {
    var button = event.currentTarget;
    var buttonRect = button.getClientRects()[0];
    var buttonId = button.id;

    var canvas = this.refs.canvas;
    var canvasRect = canvas.getBoundingClientRect();
    var context = canvas.getContext("2d");

    if (this.currentLine != null) {
      // Clicking on the same button again means leaving the connect mode
      if (buttonId === this.currentLine.startId) {
        this.currentLine = null;
        this.redrawLines(canvas, context);
        return;
      }

      // Don't allow mapping from left to left, or right to right
      // Check the first character for equality
      if (buttonId[0] === this.currentLine.startId[0])
        return;

      // Don't allow connecting several symbio to the same visio element
      var endsLeft = buttonId[0] === 'l';
      if (endsLeft)
        this.removeOldLines(buttonId);

      var eX = buttonRect.left - canvasRect.left + buttonRect.width / 2;
      var eY = buttonRect.top - canvasRect.top + buttonRect.height / 2;

      // Let end always point to the right side for scrolling support
      if (!endsLeft) {
        this.currentLine.end = [eX, eY];
        this.currentLine.endId = buttonId;
      } else {
        this.currentLine.end = this.currentLine.start;
        this.currentLine.endId = this.currentLine.startId;
        this.currentLine.start = [eX, eY];
        this.currentLine.startId = buttonId;
      }

      var masterIndex = Number(this.currentLine.startId.replace("left", ""));
      var symbioIndex = Number(this.currentLine.endId.replace("right", ""));
      this.currentMasters[masterIndex].MappingPartner = this.currentSymbioNames[symbioIndex];

      this.allLines.push(this.currentLine);
      this.currentLine = null;

      this.redrawLines(canvas, context);

      MenuBar.instance.disableContinueButton(this.allLines.length < 1);
      return;
    }

    // Create a new line for mapping,
    // but don't allow connecting several symbio to the same visio element
    if (buttonId[0] === 'l')
      this.removeOldLines(buttonId);

    var sX = buttonRect.left - canvasRect.left + buttonRect.width / 2;
    var sY = buttonRect.top - canvasRect.top + buttonRect.height / 2;
    this.currentLine = { start: [], end: [], startId: "" };
    this.currentLine.start = [sX, sY];
    this.currentLine.end = [sX, sY];
    this.currentLine.startId = buttonId;

    // We can only get the cursor position through the mouse move event
    this.scrollY = window.pageYOffset;
    this.mouseMoved = false;
  }

  drawLineToMouse(event) {
    if (this.currentLine == null)
      return;

    var canvasRect = this.refs.canvas.getBoundingClientRect();
    var x = event.clientX - canvasRect.left;
    var y = event.clientY - canvasRect.top;

    this.mouseMoved = true;
    this.currentLine.end = [x, y];
    this.redrawLines(this.refs.canvas, this.refs.canvas.getContext("2d"));
  }

  drawLineOnScroll() {
    if (this.currentLine == null)
      return;

    // Don't update the position if we haven't moved the mouse before scrolling
    // As only mouse movement sets the proper end points
    if (this.mouseMoved) {
      var delta = this.scrollY - window.pageYOffset;
      this.currentLine.end = [this.currentLine.end[0], this.currentLine.end[1] - delta];
      this.redrawLines(this.refs.canvas, this.refs.canvas.getContext("2d"));
    }

    this.scrollY = window.pageYOffset;
  }

  removeLineClicked(event) {
    event.preventDefault();
    this.removeOldLines(event.currentTarget.id.replace("rem", ""));
    this.redrawLines(this.refs.canvas, this.refs.canvas.getContext("2d"));
  }

  removeOldLines(buttonId) {
    var removedLines = this.allLines.filter(l => l.startId === buttonId || l.endId === buttonId);
    this.allLines = this.allLines.filter(l => l.startId !== buttonId && l.endId !== buttonId);

    for (var i = 0; i < removedLines.length; i++) {
      var line = removedLines[i];
      var masterIndex = Number(line.startId.replace("left", ""));
      if (this.currentMasters.length > masterIndex)
        this.currentMasters[masterIndex].MappingPartner = "";
    }

    MenuBar.instance.disableContinueButton(this.allLines.length < 1);
  }

  redrawLines(canvas, context) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    for (var i = 0; i < this.allLines.length; i++) {
      var current = this.allLines[i];
      context.beginPath();
      context.moveTo(current.start[0], current.start[1]);
      context.lineTo(current.end[0], current.end[1]);
      context.stroke();
    }

    if (this.currentLine != null) {
      context.beginPath();
      context.moveTo(this.currentLine.start[0], this.currentLine.start[1]);
      context.lineTo(this.currentLine.end[0], this.currentLine.end[1]);
      context.stroke();
    }
  }

  endMapping = (after) => {
    // Cloning an array in JS is done using slice
    // slice(0) is faster than slice()
    // Reference: https://stackoverflow.com/questions/3978492/fastest-way-to-duplicate-an-array-in-javascript-slice-vs-for-loop
    var resulting = this.currentMasters.slice(0);

    // The first entry is always the visible one, while the rest was hidden
    for (var d in this.duplicateShapes) {
      var duplicates = this.duplicateShapes[d];
      for (var j = 1; j < duplicates.length; j++) {
        duplicates[j].mappingPartner = duplicates[0].mappingPartner;
        resulting.push(duplicates[j])
      }
    }

    after(resulting);
  }

  clearConnections = () => {
    for (var i = 0; i < this.currentMasters.length; i++)
      this.currentMasters[i].MappingPartner = "";

    this.allLines = [];
    this.redrawLines(this.refs.canvas, this.refs.canvas.getContext("2d"));
    MenuBar.instance.disableContinueButton(true);
  }

  resetConnections = () => {
    // Create deep clones
    this.allLines = JSON.parse(JSON.stringify(this.initialLines));
    this.currentMasters = JSON.parse(JSON.stringify(this.initialMasters));
    this.redrawLines(this.refs.canvas, this.refs.canvas.getContext("2d"));
    MenuBar.instance.disableContinueButton(this.allLines.length < 1);
  }

  exportMapping = () => {
    var toExport = this.currentMasters.map(m => new Object({ "BaseID": m.BaseID, "MappingPartner": m.MappingPartner }));
    var downloadData = new Blob([JSON.stringify(toExport)]);
    var url = window.URL.createObjectURL(downloadData);
    var dl = document.createElement("a");

    // Edge does downloading differently
    if (/edge\//i.test(window.navigator.userAgent)) {
      dl.onclick = (event) => {
        window.navigator.msSaveBlob(downloadData, "visio-mapping-export.json");
      }
    } else {
      dl.href = url;
      dl.download = "visio-mapping-export.json";
    }

    document.body.appendChild(dl);
    dl.click();
    dl.parentNode.removeChild(dl);
  }

  openMappingImport = () => {
    this.refs.mappingImport.click();
  }

  importMapping = () => {
    if (this.refs.mappingImport.files.length < 1)
      return;

    this.setState({ status: "" });
    var file = this.refs.mappingImport.files[0];
    var fileReader = new FileReader();
    fileReader.readAsText(file);

    fileReader.onload = () => {
      try {
        var data = JSON.parse(fileReader.result);
        for (var i = 0; i < data.length; i++) {
          var masterID = data[i].BaseID;
          var mappingPartner = data[i].MappingPartner;
          var currentMaster = this.currentMasters.find(m => m.BaseID === masterID);
          if (currentMaster)
            currentMaster.MappingPartner = mappingPartner;
        }

        this.generateMappingForMasters();
        MenuBar.instance.disableContinueButton(this.allLines.length < 1);
      } catch (exception) {
        console.error(exception);
        this.setState({ status: "Mapping import failed" });
      }
    };

    fileReader.onerror = () => {
      console.error(fileReader.error);
      this.setState({ status: "Mapping import failed" });
    };
  }

  render() {
    if (this.state.componentHidden)
      return <div />;

    return (
      <div id="Mapping" className="container col-md-12 col-xs-12" style={{ paddingBottom: '80px' }}>
        <div className="col-md-12 col-xs-12 row">
          <div className="col-md-4 col-xs-4">
          </div>
          <div className="col-md-4 col-xs-4 d-flex justify-content-between">
            <PrimaryButton text="Clear" onClick={this.clearConnections} />
            <PrimaryButton text="Reset" onClick={this.resetConnections} />
            <PrimaryButton text="Export" onClick={this.exportMapping} />
            <PrimaryButton text="Import" onClick={this.openMappingImport} />
          </div>
          <div className="col-md-4 col-xs-4">
          </div>
        </div>
        <div className="col-md-12 col-xs-12 row d-flex justify-content-center">
          <input type='file' id='mapping_import' ref="mappingImport" className="d-none" onInput={this.importMapping} />
          <p>{this.state.status}</p>
        </div>
        <div className="col-md-12 col-xs-12 row">
          <div className="col-md-3 col-xs-3">
          </div>
          <div className="col-md-6 col-xs-6">
            <canvas ref="canvas" className="canvas" />
          </div>
          <div className="col-md-3 col-xs-3">
          </div>
        </div>
        {this.mappingRows}
      </div>
    );
  }
}
