import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import NotFound from './NotFound';
import unregister from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route path="/*" element={<App />} />
      <Route element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
  rootElement);

// Service workers are disabled by default in newer versions, remove any old versions on site load
unregister();
