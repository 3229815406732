import React, { Component } from 'react';
import { OnePagerTop } from './OnePagerTop.js';
import { Login } from './Login.js';
import { MenuBar } from './MenuBar.js';
import { FileUpload } from './FileUpload.js';
import { Mapping } from './Mapping.js';
import { Preview } from './Preview.js';
import { Import } from './Import.js';
import { initializeIcons, createTheme, Customizations, DefaultButton } from '@fluentui/react';

export class VisioImporter extends Component {
  static displayName = VisioImporter.name;
  static visibleComponent = "Login";
  static files = [];
  static instance;

  // Used to give each child component a unique key
  objectKey = 0;

  theme;

  constructor(props) {
    super(props);
    this.state = { loading: true, menu: null, upload: null, mapping: null, preview: null, import: null, login: null, logout: null };

    // Don't allow dropping outside of the drop-zone
    document.addEventListener("drop", function (event) {
      event.preventDefault();
    });
    window.addEventListener("dragover", function (event) {
      event.preventDefault();
    });

    // Custom theme generated by https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
    this.theme = createTheme({
      palette: {
        themePrimary: '#106ebe',
        themeLighterAlt: '#f3f8fc',
        themeLighter: '#d1e4f5',
        themeLight: '#abcdec',
        themeTertiary: '#61a1d9',
        themeSecondary: '#267cc7',
        themeDarkAlt: '#0e62ac',
        themeDark: '#0c5391',
        themeDarker: '#093d6b',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#bab8b7',
        neutralSecondary: '#a3a2a0',
        neutralPrimaryAlt: '#8d8b8a',
        neutralPrimary: '#323130',
        neutralDark: '#605e5d',
        black: '#494847',
        white: '#ffffff',
      }
    });

    // Initialize ui fabric icons
    initializeIcons();
  }

  componentDidMount() {
    VisioImporter.instance = this;
    this.setState({ loading: false, login: <Login key={this.objectKey++} finished={this.login} status={""} /> });
    Customizations.applySettings({ theme: this.theme });
  }

  oidcLogout = () => {
    window.open('logout', '_self');
  };

  // Enables the buttons menu bar
  login = () => {
    var menu = <MenuBar
      key={this.objectKey++}
      continueClicked={() => this.continueClicked()}
      backClicked={() => this.backClicked()}
      resetForm={() => this.resetForm()} />;

    this.setState({ loading: false, menu: menu, login: null, logout: <DefaultButton className="float-right" onClick={this.oidcLogout} style={{ zIndex: 1 }}>Logout</DefaultButton> });

    // Delayed execution to allow the DOM to refresh
    setTimeout(() => {
      this.startUpload();
    }, 1);
  }

  continueClicked() {
    switch (VisioImporter.visibleComponent) {
      case "FileUpload":
        this.state.upload.props.finalize[0]((mapping) => this.startMapping(mapping));
        break;
      case "Mapping":
        this.state.mapping.props.finalize[0]((masters) => this.startPreview(masters));
        break;
      case "Preview":
        this.state.preview.props.finalize[0]((masters) => this.startImport(masters));
        break;
      case "Import":
        this.state.import.props.finalize[0]();
        this.state.import.props.finalize[1](true);
        break;
      default:
        console.error("Tried to continue from unknown visible component.");
        break;
    }
  }

  backClicked() {
    switch (VisioImporter.visibleComponent) {
      case "FileUpload":
        console.error("Can't go back from the start.");
        break;
      case "Mapping":
        VisioImporter.visibleComponent = "FileUpload";
        MenuBar.instance.disableBackButton(true);
        this.state.upload.props.finalize[1](false);
        this.state.mapping.props.finalize[1](true);
        MenuBar.instance.disableContinueButton(VisioImporter.files.length <= 0);
        break;
      case "Preview":
        VisioImporter.visibleComponent = "Mapping";
        this.state.mapping.props.finalize[1](false);
        this.state.preview.props.finalize[1](true);
        MenuBar.instance.disableContinueButton(false);
        break;
      case "Import":
        VisioImporter.visibleComponent = "Preview";
        this.state.preview.props.finalize[1](false);
        this.state.import.props.finalize[1](true);
        MenuBar.instance.disableContinueButton(false);
        break;
      default:
        console.error("Tried go back from unkown visible component.");
        break;
    }

    this.forceUpdate();
    this.jumpToImportForm();
    this.fixBarPosition();
  }

  resetForm() {
    VisioImporter.visibleComponent = "FileUpload";
    this.setState({ upload: <FileUpload key={this.objectKey++} finalize={[]} />, mapping: null, preview: null, import: null });
    MenuBar.instance.disableContinueButton(true);
    MenuBar.instance.disableBackButton(true);
    this.fixBarPosition();
    this.jumpToImportForm();
  }

  startUpload() {
    VisioImporter.visibleComponent = "FileUpload";

    this.setState({ status: "", upload: <FileUpload key={this.objectKey++} finalize={[]} /> });

    this.jumpToImportForm();
    this.fixBarPosition();
  }

  startMapping(mapping) {
    VisioImporter.visibleComponent = "Mapping";
    this.state.upload.props.finalize[1](true);

    this.setState({ mapping: <Mapping key={(this.objectKey++)} finalize={[]} mapping={mapping} /> });

    this.jumpToImportForm();
    this.fixBarPosition();
  }

  startPreview(masters) {
    this.state.mapping.props.finalize[1](true);
    VisioImporter.visibleComponent = "Preview";

    this.setState({ preview: <Preview key={this.objectKey++} finalize={[]} masters={masters} /> });

    this.jumpToImportForm();
    this.fixBarPosition();
  }

  startImport(masters) {
    VisioImporter.visibleComponent = "Import";
    this.state.preview.props.finalize[1](true);

    this.setState({ import: <Import key={this.objectKey++} masters={masters} finalize={[]} /> });

    this.jumpToImportForm();
    this.fixBarPosition();
  }

  jumpToImportForm() {
    // HACK: Delayed execution to allow the DOM to refresh
    setTimeout(() => {
      document.getElementById("ImportForm").scrollIntoView();
    }, 1);
  }

  fixBarPosition() {
    // HACK: Delayed execution to allow the DOM to refresh
    setTimeout(() => {
      if (VisioImporter.visibleComponent === "Mapping") {
        MenuBar.instance.adjustOnScroll();
        return;
      }

      var onePager = document.getElementById("OnePager");
      var importForm = document.getElementById("ImportForm");

      MenuBar.instance.setBarTop((importForm.clientHeight + onePager.clientHeight + 50) + "px");
    }, 1);
  }

  render() {
    if (this.state.loading)
      return (
        <div id="ImportForm" className="pagerW">
          <p><em>Loading...</em></p>
          <img src="loading.gif" style={{ width: '40px' }} alt="Loading" />
        </div>
      );

    return (
      <div>
        <OnePagerTop />
        <div id="ImportForm" className="pagerW" style={{ marginTop: '50px', marginBottom: '80px' }}>
          {this.state.logout}
          {this.state.login}
          {this.state.upload}
          {this.state.mapping}
          {this.state.preview}
          {this.state.import}
          {this.state.menu}
        </div>
      </div>
    );
  }
}
