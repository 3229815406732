import React, { Component } from 'react';
import { VisioImporter } from './VisioImporter';

export class OnePagerTop extends Component {
  render() {
    let uploadStyle = VisioImporter.visibleComponent == "FileUpload" ? { textDecoration: 'underline', textDecorationColor: '#106ebe' } : {};
    let mappingStyle = VisioImporter.visibleComponent == "Mapping" ? { textDecoration: 'underline', textDecorationColor: '#106ebe' } : {};
    let previewStyle = VisioImporter.visibleComponent == "Preview" ? { textDecoration: 'underline', textDecorationColor: '#106ebe' } : {};
    let importStyle = VisioImporter.visibleComponent == "Import" ? { textDecoration: 'underline', textDecorationColor: '#106ebe' } : {};

    return (
      <div id="OnePager" className="pagerW">
        <div className="container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px' }}>
            <div style={{ height: 'min-content' }}>
              <p className="blue-text" style={{ fontSize: 'xx-large', marginBottom: '0' }}>
                Visio® - Importer
              </p>
              <p className="gray-black-text">
                IMPORT YOUR VISIO® PROCESS TO SYMBIO
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '50px' }}>
            <img src="Illustration-Business-Manager.svg" height="200"></img>
          </div>
        </div>

        <div className="white-text" style={{ height: '1px' }}></div>

        <div className="gray-black-text row" style={{ margin: '40px 40px 40px 40px' }}>
          <div className="col-md-2"></div>
          <div className="col-md-2 blue-circle-text">
            <h4 style={uploadStyle}>1. Upload</h4>
            <p>Select files<br />or upload via<br />Drag and Drop.</p>
          </div>

          <div className="col-md-2 blue-circle-text">
            <h4 style={mappingStyle}>2. Mapping</h4>
            <p>Choose how your<br />Visio® shapes are<br />mapped to Symbio objects.</p>
          </div>

          <div className="col-md-2 blue-circle-text">
            <h4 style={previewStyle}>3. Review</h4>
            <p>Review your mapped<br />processes before<br />importing to Symbio.</p>
          </div>

          <div className="col-md-2 blue-circle-text">
            <h4 style={importStyle}>4. Import</h4>
            <p>Import your Visio®<br />processes to<br />Symbio now.</p>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="white-text" style={{ height: '1px' }}></div>
      </div>
    );
  }
}
