import React, { Component } from 'react';
import { OnePagerBottom } from './OnePagerBottom.js';
import { VisioImporter } from './VisioImporter.js';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <VisioImporter />
        <OnePagerBottom />
      </div>
    );
  }
}
