import React, { Component } from 'react';
import { VisioImporter } from './VisioImporter.js';
import { MenuBar } from './MenuBar.js';
import { Checkbox, Stack, IconButton } from '@fluentui/react';

export class FileUpload extends Component {
  static displayName = FileUpload.name;

  static mergeShapes;
  static addTasks;
  static guessMapping;
  static resolveSubgroups;
  static useExistingElements;

  filePreviews = [];
  previewCount = 0;

  constructor(props) {
    // HACK HACK HACK: This is horrible but let's the parent call a function of the child
    props.finalize[0] = (after) => {
      this.sendFiles(after);
    };
    props.finalize[1] = (hidden) => {
      this.setState({ componentHidden: hidden });
    };

    super(props);

    this.state = {
      status: "",
      componentHidden: false,
      dropTextHidden: false,
      dropZoneClassName: "upload-drop-zone drop-zone",
    };

    // Remove stale previews of previously uploaded files
    VisioImporter.files = [];
    MenuBar.instance.disableContinueButton(true);

    VisioImporter.instance.jumpToImportForm();

    // These default to true, 'resolveSubgroups' doesn't
    FileUpload.mergeShapes = true;
    FileUpload.guessMapping = true;
    FileUpload.addTasks = true;
    FileUpload.useExistingElements = true;
    FileUpload.resolveSubgroups = false;

    // This binding is necessary to make `this` work in the callback
    this.allowDrop = this.allowDrop.bind(this);
    this.stopDragging = this.stopDragging.bind(this);
    this.fileDropped = this.fileDropped.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.checkboxChanged = this.checkboxChanged.bind(this);
  }

  allowDrop(event) {
    event.preventDefault();
    this.setState({ dropZoneClassName: "highlight-drop-zone drop-zone" });
  }

  stopDragging(event) {
    event.preventDefault();
    this.setState({ dropZoneClassName: (this.state.dropTextHidden ? "preview-drop-zone drop-zone" : "upload-drop-zone drop-zone") });
  }

  fileDropped(event) {
    event.preventDefault();

    for (var i = 0; i < event.dataTransfer.files.length; i++)
      this.handleFileInput(event.dataTransfer.files[i]);
  }

  fileSelected() {
    var fileInput = document.getElementById("visio_file");
    this.handleFileInput(fileInput.files[0]);
  }

  handleFileInput(file) {
    // Revert to error status if we didn't drop a visio file
    if (!file.name.match("vsdx")) {
      var name = VisioImporter.files.length > 0 ? "preview-drop-zone drop-zone" : "upload-drop-zone drop-zone";
      var status = file.name.match("vsd") ? "Please convert your file to the vsdx format." : "Unsupported format: " + file.name;
      this.setState({ status: status, dropZoneClassName: name });
      return;
    }

    // Would usually check f.lastModified == file.lastModified too,
    // but the automatic build doesn't know about file having a 'lastModified' property
    if (VisioImporter.files.find(f => f.size === file.size && f.name === file.name) != null)
      return;

    MenuBar.instance.disableContinueButton(false);
    this.setState({ dropTextHidden: true, dropZoneClassName: "preview-drop-zone drop-zone", status: "" });

    VisioImporter.files.push(file);

    var filename = file.name;
    if (filename.length > 23)
      filename = filename.substr(0, 20) + "...";

    var previewId = "preview" + (this.previewCount++);
    var img = React.createElement("img", { className: "img-responsive", title: file.name, src: "https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/png/visio_32x1.png", style: { marginLeft: '33px' } });
    var txt = React.createElement("p", {}, filename);
    var x = <IconButton
      styles={{ root: { scale: "50%" } }}
      iconProps={{ iconName: "Cancel" }}
      ariaLabel="Datei entfernen"
      onClick={() => { this.removePreview(previewId, file.name) }}
    />;
    this.filePreviews.push(React.createElement("div", { key: previewId, className: "col-md-2", id: previewId }, img, x, txt));

    VisioImporter.instance.fixBarPosition();
  }

  removePreview(previewId, filename) {
    this.filePreviews = this.filePreviews.filter(p => p.key !== previewId);
    VisioImporter.files = VisioImporter.files.filter(f => f.name !== filename);

    if (VisioImporter.files.length === 0) {
      this.setState({ dropTextHidden: false, dropZoneClassName: "upload-drop-zone drop-zone" });
      MenuBar.instance.disableContinueButton(true);
    }

    this.forceUpdate();
    VisioImporter.instance.fixBarPosition();
  }

  // after called as "after(result)" unless an error occurs
  async sendFiles(after) {
    if (VisioImporter.files.length < 1) {
      after(null);
      return;
    }

    // Reset the name field
    var fileInput = document.getElementById("visio_file");
    fileInput.value = "";

    MenuBar.instance.disableContinueButton(true);
    MenuBar.instance.setLoading(true);
    this.setState({ status: "Loading..." });
    VisioImporter.instance.fixBarPosition();

    var request = new XMLHttpRequest();
    request.open('post', 'GetSuggestedVisioMapping', true);
    request.onload = function () {
      if (request.response != null && request.status >= 200 && request.status <= 299) {
        try {
          var data = JSON.parse(request.responseText);
          after(data);
          this.setState({ status: "" });
          MenuBar.instance.setLoading(false);
          return;
        }
        catch (e) {
          console.debug(e);
        }
      }

      this.setState({ status: "Upload failed. Please try again later." });
      MenuBar.instance.setLoading(false);
    }.bind(this);

    var formData = new FormData();
    formData.append("guessMapping", String(FileUpload.guessMapping));
    for (var i = 0; i < VisioImporter.files.length; i++)
      formData.append("fileToUpload" + i, VisioImporter.files[i]);

    request.send(formData);
  }

  checkboxChanged = event => {
    var box = event.currentTarget;
    switch (box.id) {
      case "mergeShapes":
        FileUpload.mergeShapes = !FileUpload.mergeShapes;
        break;
      case "guessMapping":
        FileUpload.guessMapping = !FileUpload.guessMapping;
        break;
      case "addTasks":
        FileUpload.addTasks = !FileUpload.addTasks;
        break;
      case "resolveSubgroups":
        FileUpload.resolveSubgroups = !FileUpload.resolveSubgroups;
        break;
      case "useExistingElements":
        FileUpload.useExistingElements = !FileUpload.useExistingElements;
        break;
      default:
        console.error("Changed state of unrecognized checkbox '" + box.id + "'.");
        break;
    }

    this.forceUpdate();
  }

  render() {
    if (this.state.componentHidden)
      return <div />;

    let dropText = this.state.dropTextHidden ? <div /> : <p>Alternativly add your Visio files via drag and drop here.</p>;
    return (
      <div id="FileUpload" className="container">
        <div style={{ margin: '0 50px 0 50px' }}>
          <form encType="multipart/form-data" method="post">
            <div>
              <div><label htmlFor="image_file">Please chose Visio files to import.</label></div>
              <div><input type="file" name="visio_file" id="visio_file" onChange={this.fileSelected} /></div>
            </div>
          </form>

          <hr />
          <div className={this.state.dropZoneClassName} id="drop-zone" onDrop={this.fileDropped} onDragOver={this.allowDrop} onDragLeave={this.stopDragging}>
            {dropText}
            <div className="row col-md-12">
              {this.filePreviews}
            </div>
          </div>

          <p>{this.state.status}</p>
          <hr />
          <Stack tokens={{ childrenGap: 10 }}>
            {/* Important! If you ever change 'defaultChecked', you need to adapt the constructor of FileUpload as well */}
            <Checkbox id="mergeShapes" label="Treat Visio shapes in different files, but with the same name, as one shape." checked={FileUpload.mergeShapes} onChange={this.checkboxChanged} />
            <Checkbox id="guessMapping" label="Suggest a mapping based on the stencil sets used in Visio." checked={FileUpload.guessMapping} onChange={this.checkboxChanged} />
            <Checkbox id="addTasks" label="Insert a Task in case a gateway contains a description." checked={FileUpload.addTasks} onChange={this.checkboxChanged} />
            <Checkbox id="resolveSubgroups" label="Divide groups with subgroups during the import." checked={FileUpload.resolveSubgroups} onChange={this.checkboxChanged} />
            <Checkbox id="useExistingElements" label="Re-use elements (e.g. roles) already existing in Symbio." checked={FileUpload.useExistingElements} onChange={this.checkboxChanged} />
          </Stack>
          <hr />
        </div>
      </div>
    );
  }
}
