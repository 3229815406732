import React, { Component } from 'react';
import { DefaultButton } from '@fluentui/react';

export class Login extends Component {
  static displayName = Login.name;

  constructor(props) {
    super(props);

    this.state = { display: <div className="d-flex" style={{ alignItems: "center", flexDirection: "column" }}><img style={{ width: "40px" }} src="loading.gif" alt="Loading" /><p>Loading application...</p></div>, logout: null };

    // Allow usage of 'this' inside the callback function
    this.displayAuthorizationError = this.displayAuthorizationError.bind(this);

    // If the server reports an OIDC token already exists, we were just redirected from the login,
    // or have had an login somewhen before during this active session (usually 30 minutes timeout)
    var request = new XMLHttpRequest();
    request.open('get', 'isUserAuthenticated', true);
    request.onload = function () {
      try {
        var isLoggedIn = Boolean(JSON.parse(request.responseText));
        if (!isLoggedIn) {
          const team = window.location.pathname.replace("/", "");
          if (team && team !== "")
            window.open('login?companyShortName=' + team, '_self');
          else
            this.setState({ display: <span>To sign in use your company link, e.g. https://visio.symbio.cloud/company</span> });

          return;
        }

        // Even if logged in successfully, only allow authorized users forward
        var authorizedRequest = new XMLHttpRequest();
        authorizedRequest.open('get', 'isUserAuthorized', true);
        authorizedRequest.onload = function () {
          try {
            var isAuthorized = Boolean(JSON.parse(authorizedRequest.responseText));
            if (isAuthorized) {
              props.finished();
              return;
            }
          } catch {
            // Ignore any errors during handling of the request and assume we're not authorized
          }

          this.displayAuthorizationError();
        }.bind(this);

        authorizedRequest.send();
      } catch {
        // Ignore any errors during handling of the request and assume we're not logged in
      }
    }.bind(this);

    request.send();
  }

  displayAuthorizationError = () => {
    this.setState({ display: <p>Authorization error: User does not have the required privileges to use the importer.</p>, logout: <DefaultButton className="float-right" onClick={this.oidcLogout} style={{ zIndex: 1 }}>Logout</DefaultButton> });
  }

  oidcLogin = () => {
    const team = window.location.pathname.replace("/", "");
    window.open('login?companyShortName=' + team, '_self');
  };

  oidcLogout = () => {
    window.open('logout', '_self');
  };

  render() {
    return (
      <div>
        {this.state.logout}
        <div className="container gray-black-text">
          <div style={{ margin: '25px 50px 25px 50px' }}>
            <div className="d-flex justify-content-center" style={{ minHeight: "200px" }}>
              {this.state.display}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
