import React, { Component } from 'react';

import './custom.css'

export default class NotFound extends Component {
  static displayName = NotFound.name;

  render() {
    return (
      <div>
        <h1>404: Page not found</h1>
      </div>
    );
  }
}
