import React, { Component } from 'react';
import { VisioImporter } from './VisioImporter.js';
import { MenuBar } from './MenuBar.js';
import { FileUpload } from './FileUpload.js';
import Viz from 'viz.js';

// Unused, see the HACK: below
// import { Module, render } from 'viz.js/lite.render.js';

export class Preview extends Component {
  static displayName = Preview.name;

  previews = [];
  renderStrings = [];
  fileNames = [];

  constructor(props) {
    // HACK HACK HACK: This is horrible but let's the parent call a function of the child
    props.finalize[0] = (after) => {
      after(this.props.masters);
    };
    props.finalize[1] = (hidden) => {
      this.setState({ componentHidden: hidden });
    };

    super(props);

    MenuBar.instance.disableContinueButton(true);
    MenuBar.instance.disableBackButton(true);
    MenuBar.instance.setLoading(true);
    this.state = { status: "Lade...", componentHidden: false };

    // This binding is necessary to make `this` work in the callback
    this.renderPreview = this.renderPreview.bind(this);
    this.elementRendered = this.elementRendered.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  componentDidMount() {
    // Disable previews on internet explorer
    if (/msie\s|trident\//i.test(window.navigator.userAgent)) {
      MenuBar.instance.disableContinueButton(false);
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
      this.setState({ status: "Previews are not available in the Internet Explorer." });
      return;
    }

    this.loadPreview();
  }

  async loadPreview() {
    var request = new XMLHttpRequest();
    request.open('post', 'GeneratePreview', true);
    request.onload = function () {
      if (request.response != null && request.status >= 200 && request.status <= 299) {
        try {
          var data = JSON.parse(request.responseText);
          if (data.length > 0) {
            this.parsePreview(data);
            return;
          }
        }
        catch (e) {
          console.debug(e);
        }
      }

      this.setState({ status: "Preview could not be created." });
      MenuBar.instance.disableContinueButton(false);
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
    }.bind(this);

    var formData = new FormData();
    formData.append("mapping", JSON.stringify(this.props.masters));
    formData.append("addTasks", String(FileUpload.addTasks));
    formData.append("resolveSubgroups", String(FileUpload.resolveSubgroups));
    for (var i = 0; i < VisioImporter.files.length; i++)
      formData.append("fileToParse" + i, VisioImporter.files[i]);

    request.send(formData);
  }

  parsePreview(result) {
    var getShape = type => {
      return type.indexOf("rule") >= 0 ? "diamond" : "square";
    };

    var renderedFiles = 0;
    for (var i = 0; i < result.length; i++) {
      var filename = result[i].RelatedFileName;
      filename = filename.substring(0, filename.lastIndexOf('.'));

      // Only allow a-z, A-Z and 0-9 in this name
      // After invalid characters are removed, remove leading digits as well
      var graphName = filename.replace(/[^0-9a-zA-Z]+/g, "").replace(/^[0-9]+/g, "");
      var renderString = "digraph " + graphName + " {";

      var lanes = {};
      for (var j = 0; j < result[i].Elements.length; j++) {
        var element = result[i].Elements[j];
        var labelName = element.DisplayName.split('\n').join('').trim();
        if (labelName == null || labelName === "")
          labelName = element.Type;

        // Let long names span several lines
        if (labelName.length > 20)
          for (var k = 20; k < labelName.length; k += 20)
            labelName = labelName.substring(0, k) + "\n" + labelName.substring(k, labelName.length);

        var toAdd = element.Index + " [shape=\"" + getShape(element.Type) + "\",label=\"" + labelName + "\"];";

        // Directly add to the render string if there is no role.
        // If there is one, add it to the roles cluster at the end.
        if (element.Role == null || element.Role === "")
          renderString += "\n\t" + toAdd;
        else {
          var laneName = "\"" + element.Role.split('\n').join('').trim() + "\"";
          if (lanes[laneName] == null)
            lanes[laneName] = [];

          lanes[laneName].push(toAdd);
        }

        if (element.Predecessors.length > 0)
          for (var m = 0; m < element.Predecessors.length; m++)
            renderString += "\n\t" + element.Predecessors[m] + " -> " + element.Index + ";";
      }

      var laneCount = 0;
      for (var l in lanes) {
        renderString += "\n\tsubgraph cluster" + (laneCount++) + " {";
        renderString += "\n\t\tstyle = filled;";
        renderString += "\n\t\tcolor = lightgrey;";
        renderString += "\n\t\tnode[style = filled, color = white];";

        for (/*var*/ j = 0; j < lanes[l].length; j++)
          renderString += "\n\t\t" + lanes[l][j];

        renderString += "\n\t\tlabel = " + l + ";\n\t}";
      }

      renderString += "\n}";

      var title = React.createElement("summary", { style: { fontSize: "24px", display: "list-item" } }, result[renderedFiles++].RelatedFileName);
      // HACK: Use invisible p tags to store information
      // This element needs to be the second child! (index 1 in childnodes)
      var pIndex = React.createElement("p", { hidden: "hidden" }, String(i));
      var details = React.createElement("details", { onToggle: this.renderPreview }, title, pIndex);
      var previewDetails = React.createElement("div", { key: ("preview" + i) }, details);

      this.fileNames[i] = title.innerText;
      this.renderStrings[i] = renderString;

      this.previews.push(previewDetails);
    }

    this.setState({ status: "" });
    MenuBar.instance.setLoading(false);
    MenuBar.instance.disableContinueButton(false);
    MenuBar.instance.disableBackButton(false);
    VisioImporter.instance.fixBarPosition();
  }

  renderPreview(event) {
    var details = event.currentTarget;
    if (!details.open || details.childNodes[1].textContent === "rendered") {
      VisioImporter.instance.fixBarPosition();
      return;
    }

    MenuBar.instance.disableContinueButton(true);
    MenuBar.instance.disableBackButton(true);
    MenuBar.instance.setLoading(true);

    // Using the npm package does not work for the deployed web page
    // HACK: Include lite-render.js as static file in the wwwroot and reference it here
    // var viz = new Viz({ Module, render });
    var workerURL = "lite-render.js";
    var viz = new Viz({ workerURL });

    // Fix the bar position before rendering, as that may take a while
    // We'll update it again after rendering
    this.setState({ status: "Zeichne Preview..." });
    VisioImporter.instance.fixBarPosition();

    var index = Number(details.childNodes[1].textContent);
    details.childNodes[1].textContent = "rendered";
    viz.renderSVGElement(this.renderStrings[index])
      .then(element => this.elementRendered(element, index, details))
      .catch(this.renderError);
  }

  elementRendered(element, index, details) {
    var can = document.createElement("canvas");
    var img = document.createElement("img");
    img.onload = (event) => {
      can.width = img.width;
      can.height = img.height;
      can.getContext("2d").drawImage(img, 0, 0);

      var dl = document.createElement("a");

      // Edge does downloading differently
      if (/edge\//i.test(window.navigator.userAgent)) {
        // Cast to any to avoid missing method errors for msToBlob
        var blob = can.msToBlob();
        dl.onclick = (event) => {
          window.navigator.msSaveBlob(blob, this.fileNames[index] + ".png");
        }
      } else {
        dl.href = can.toDataURL();
        dl.download = this.fileNames[index] + ".png";
      }

      dl.innerText = "Download PNG";
      details.appendChild(document.createElement("hr"));
      details.appendChild(dl);
      details.appendChild(document.createElement("hr"));

      can.remove();
      VisioImporter.instance.fixBarPosition();
    };

    var svgURL = new XMLSerializer().serializeToString(element);
    img.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgURL);

    var hElement = element;
    hElement.setAttribute("preserveAspectRatio", "xMidYMin meet");
    hElement.style["max-width"] = "1200px";

    details.appendChild(element);

    // Refresh the bar position before finally setting it after the timeout (the delay is noticeable otherwise)
    VisioImporter.instance.fixBarPosition();

    // Don't let the image get too high while cutting the width
    // HACK: Delayed execution to allow the DOM to refresh
    setTimeout(() => {
      // HACK: The first 'g' tag (id graph0) contains the "real" size in case the svg is oversized
      var graph0 = hElement.querySelector("#graph0");
      hElement.setAttribute("height", graph0.getBoundingClientRect().height + "px");

      this.setState({ status: "" });
      VisioImporter.instance.fixBarPosition();
      MenuBar.instance.disableContinueButton(false);
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
    }, 1);
  }

  renderError(error) {
    console.error(error);
    this.setState({ status: "Preview could not be created." });
    MenuBar.instance.disableContinueButton(false);
    MenuBar.instance.disableBackButton(false);
    MenuBar.instance.setLoading(false);
    VisioImporter.instance.fixBarPosition();
  }

  render() {
    if (this.state.componentHidden)
      return <div />;

    return (
      <div id="Preview" className="container">
        <div style={{ margin: '0 50px 0 50px' }}>
          <div>
            {this.previews}
          </div>
          <p>{this.state.status}</p>
        </div>
      </div>
    );
  }
}
