import React, { Component } from 'react';
import { VisioImporter } from './VisioImporter.js';
import { MenuBar } from './MenuBar.js';
import { FileUpload } from './FileUpload.js';
import { Checkbox, PrimaryButton, Dropdown } from '@fluentui/react';

export class Import extends Component {
  static displayName = Import.name;

  boxId = 0;
  languages = [];
  languageCheckboxes = [];
  storages = [];
  selectedStorage;

  constructor(props) {
    props.finalize[0] = (after) => {
      // Unused
    };
    props.finalize[1] = (hidden) => {
      this.setState({ componentHidden: hidden });
    };

    super(props);

    MenuBar.instance.disableContinueButton(true);
    MenuBar.instance.disableBackButton(true);
    MenuBar.instance.setLoading(true);
    this.state = {
      status: "Loading Storages...",
      submitTokenDisabled: true,
      componentHidden: false,
      failedFiles: [],
      inputElementsDisabled: false,
      languageSelectionHidden: true,
      importAreaHidden: true,
    };
    this.loadStorages();

    // This binding is necessary to make `this` work in the callback
    this.selectStorage = this.selectStorage.bind(this);
    this.loadLanguages = this.loadLanguages.bind(this);
    this.startImport = this.startImport.bind(this);
  }

  loadStorages = () => {
    var request = new XMLHttpRequest();
    request.open('post', 'GetStorages', true);
    request.onload = function () {
      if (request.response != null && request.status >= 200 && request.status <= 299) {
        try {
          const result = JSON.parse(request.responseText);
          if (result.length > 0) {
            this.parseStorages(result);
            return;
          }
        }
        catch (e) {
          console.debug(e);
        }
      }

      this.setState({ status: "Failed to load storages. Please make sure this service is registered for use." });
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
    }.bind(this);

    request.send();
  }

  parseStorages(result) {
    var selectStorage = document.getElementById("SelectStorage");
    if (selectStorage.hasAttribute("hidden"))
      selectStorage.attributes.removeNamedItem("hidden");

    this.storages = [];

    for (var i = 0; i < result.length; i++)
      this.storages.push({ key: result[i]["id"], text: result[i]["name"] });

    this.setState({ status: "" });
    MenuBar.instance.disableBackButton(false);
    MenuBar.instance.setLoading(false);
    VisioImporter.instance.fixBarPosition();
  }

  async loadLanguages() {
    MenuBar.instance.disableBackButton(true);
    MenuBar.instance.setLoading(true);
    this.setState({ status: "Loading languages...", failedFiles: [], inputElementsDisabled: true, submitTokenDisabled: true, importAreaHidden: true, languageSelectionHidden: true });

    var request = new XMLHttpRequest();
    request.open('post', 'GetLanguages', true);
    request.onload = function () {
      if (request.response != null && request.status >= 200 && request.status <= 299) {
        try {
          const result = JSON.parse(request.responseText);
          if (result.length > 0) {
            this.parseLanguages(result);
            return;
          }
        }
        catch (e) {
          console.debug(e);
        }
      }

      this.setState({ status: "Failed to load language selection.", inputElementsDisabled: false });
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
    }.bind(this);

    var formData = new FormData();
    formData.append("storageTenantId", this.selectedStorage);
    request.send(formData);
  }

  parseLanguages(result) {
    // The boxId is used to set the key of the language checkboxes.
    // The key allows React to differentiate between the checkboxes,
    // so they need to be different when we parse new languages.
    this.boxId += this.languages.length;

    this.languages = [];
    this.languageCheckboxes = [];

    for (var i = 0; i < result.length; i++)
      this.languages.push(result[i]);

    this.setState({ status: "", languageSelectionHidden: false, inputElementsDisabled: false });
    MenuBar.instance.disableBackButton(false);
    MenuBar.instance.setLoading(false);
    VisioImporter.instance.fixBarPosition();
  }

  languagesSelected = () => {
    if (!this.atLeastOneLanguageSelected()) {
      this.setState({ status: "At least one language must be selected!", importAreaHidden: true, submitTokenDisabled: true });
      VisioImporter.instance.fixBarPosition();
      return;
    }

    this.setState({ status: "", importAreaHidden: false, submitTokenDisabled: false });
    VisioImporter.instance.fixBarPosition();
  }

  atLeastOneLanguageSelected = () => {
    for (var i = 0; i < this.languages.length; i++)
      if (document.getElementById("box" + i).checked)
        return true;

    return false;
  }

  async startImport() {
    this.setState({ status: "Importing...", submitTokenDisabled: true, inputElementsDisabled: true, failedFiles: [] });
    MenuBar.instance.disableBackButton(true);
    MenuBar.instance.setLoading(true);
    VisioImporter.instance.fixBarPosition();

    var request = new XMLHttpRequest();
    request.open('post', 'ImportVisioFiles', true);
    request.onload = function () {
      if (request.status < 200 || request.status > 299) {
        this.setState({ status: "Import of all files failed.", failedFiles: [], submitTokenDisabled: false, inputElementsDisabled: false });
        MenuBar.instance.disableBackButton(false);
        MenuBar.instance.setLoading(false);
        VisioImporter.instance.fixBarPosition();
        return;
      }

      // Check if we can parse a response text, assume everything worked if the code is 200 even without text
      if (request.responseText != null && request.responseText !== "") {

        // If the import didn't fail an empty array is sent back
        var data = JSON.parse(request.responseText);
        if (data.length > 0) {
          this.setState({ status: "Import of the following files failed:", failedFiles: data, submitTokenDisabled: false, inputElementsDisabled: false });
          MenuBar.instance.disableBackButton(false);
          MenuBar.instance.setLoading(false);
          VisioImporter.instance.fixBarPosition();
          return;
        }
      }

      this.setState({ status: "Import successful! You can now log into Symbio to view your new processes.", failedFiles: [], submitTokenDisabled: false, inputElementsDisabled: false });
      MenuBar.instance.disableBackButton(false);
      MenuBar.instance.setLoading(false);
      VisioImporter.instance.fixBarPosition();
    }.bind(this);

    var langs = [];
    for (var i = 0; i < this.languages.length; i++)
      if (this.languages[i].Mandatory || document.getElementById("box" + i).checked)
        langs.push(this.languages[i].LCID);

    var formData = new FormData();
    formData.append("storageTenantId", this.selectedStorage);
    formData.append("languages", langs.join(','));
    formData.append("storeMapping", document.getElementById("storeMapping").checked);
    formData.append("addTasks", String(FileUpload.addTasks));
    formData.append("resolveSubgroups", String(FileUpload.resolveSubgroups));
    formData.append("useExistingRelatedElements", String(FileUpload.useExistingElements));
    formData.append("mapping", JSON.stringify(this.props.masters));
    for (/*var*/ i = 0; i < VisioImporter.files.length; i++)
      formData.append("file" + i + VisioImporter.files[i].name, VisioImporter.files[i]);

    request.send(formData);
  }

  selectStorage = (_, option) => {
    this.selectedStorage = option["key"];
    this.loadLanguages();
  }

  render() {
    if (this.state.componentHidden)
      return <div />;

    let failed = this.state.failedFiles.map((text, index) => { return <li key={index}>{text}</li>; });

    const dropdownStyles = {
      dropdown: { width: 420 },
    };

    var i = 0;
    const languageCheckboxes = [];
    for (const language of this.languages)
      languageCheckboxes.push(<Checkbox key={"box" + (this.boxId + i)} id={"box" + (i++)} label={language.DisplayName} className="mb-1" onChange={this.languagesSelected} disabled={this.state.inputElementsDisabled} />);

    return (
      <div id="Import" className="container">
        <div style={{ margin: '0 50px 0 50px' }}>
          <div id="SelectStorage" hidden="hidden">
            <Dropdown
              placeholder="Select a storage"
              label="Please select the Symbio storage which should be used for import:"
              options={this.storages}
              styles={dropdownStyles}
              onChange={this.selectStorage}
              disabled={this.state.inputElementsDisabled}
            />
          </div>
          <div id="SelectLang" hidden={this.state.languageSelectionHidden} >
            <br />
            <p>Symbio supports multiple languages. Please select which ones should be used for import (multiple selection possible):</p>
            <div>{languageCheckboxes}</div>
          </div>
          <div id="ImportArea" hidden={this.state.importAreaHidden} >
            <br />
            <Checkbox id="storeMapping" label="Save current mapping after import for future logins." disabled={this.state.inputElementsDisabled} />
            <br />
            <PrimaryButton text="Start the import" onClick={this.startImport} disabled={this.state.submitTokenDisabled} />
          </div>
          <br />
          <br />
          <p>{this.state.status}</p>
          <ul>
            {failed}
          </ul>
        </div>
      </div>
    );
  }
}
