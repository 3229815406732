import React, { Component } from 'react';
import { VisioImporter } from './VisioImporter.js';
import { PrimaryButton, DefaultButton, Modal, IconButton, Dialog, DialogType, DialogFooter } from '@fluentui/react';

export class MenuBar extends Component {
  static displayName = MenuBar.name;

  static instance;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      continueButtonDisabled: true,
      backButtonDisabled: true,
      barTop: "0px",
      fixButtonsBar: false,
      reset: <div />,
      help: <div />
    };

    // This binding is necessary to make `this` work in the callback
    this.adjustOnScroll = this.adjustOnScroll.bind(this);
    this.resetClicked = this.resetClicked.bind(this);
    this.helpClicked = this.helpClicked.bind(this);
    this.isContinueButtonDisabled = this.isContinueButtonDisabled.bind(this);
    this.disableContinueButton = this.disableContinueButton.bind(this);
    this.disableBackButton = this.disableBackButton.bind(this);
  }

  adjustOnScroll() {
    if (VisioImporter.visibleComponent !== "Mapping") {
      this.setState({ fixButtonsBar: false });
      return;
    }

    var scrollBarHeight = this.refs.ButtonsBar.clientHeight;
    var onePager = document.getElementById("OnePager");
    var importForm = document.getElementById("ImportForm");
    var offset = window.pageYOffset + window.innerHeight - 200;
    if (offset < onePager.clientHeight || offset > importForm.clientHeight + onePager.clientHeight - scrollBarHeight) {
      this.setState({ fixButtonsBar: false });
      return;
    }

    var topY = Math.min(window.pageYOffset + window.innerHeight - scrollBarHeight, importForm.clientHeight + onePager.clientHeight);
    this.setState({ barTop: (topY + "px"), fixButtonsBar: true });
  }

  componentDidMount() {
    MenuBar.instance = this;

    document.addEventListener("scroll", this.adjustOnScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.adjustOnScroll, false);
  }

  helpClicked() {
    var help = <Help
      helpClosed={(event) => { event.preventDefault(); this.setState({ help: <div /> }) }}
    />
    this.setState({ help: help });
  }

  resetClicked() {
    var reset = <Reset
      resetConfirmed={() => { this.setState({ reset: <div />, loading: false }); this.props.resetForm() }}
      resetAborted={() => this.setState({ reset: <div /> })}
    />
    this.setState({ reset: reset });
  }

  setBarTop(top) {
    this.setState({ barTop: top, fixButtonsBar: VisioImporter.visibleComponent === "Mapping" });
  }

  isContinueButtonDisabled() {
    return this.state.continueButtonDisabled;
  }

  disableContinueButton(disabled) {
    this.setState({ continueButtonDisabled: disabled });
  }

  disableBackButton(disabled) {
    this.setState({ backButtonDisabled: disabled });
  }

  setLoading(loading) {
    this.setState({ loading: loading });
  }

  render() {
    let style = { top: this.state.barTop };
    if (this.state.fixButtonsBar)
      style = { bottom: 0, position: "fixed" };

    let loading = this.state.loading ? <img className="load-screen" src="loading.gif" alt="Loading" /> : <div />;
    return (
      <div>
        {this.state.help}
        {this.state.reset}
        <div ref="ButtonsBar" className="buttons-bar" style={style}>
          <div style={{ maxWidth: '1400px', minWidth: '550px', margin: '0 50px 0 50px' }}>
            <PrimaryButton text="Reset" onClick={this.resetClicked} style={{ float: 'left', marginLeft: '120px' }} />
            <DefaultButton text="Help" onClick={this.helpClicked} style={{ float: 'left', marginLeft: '10px' }} />
            {loading}
            <PrimaryButton text="Continue" disabled={this.state.continueButtonDisabled} onClick={this.props.continueClicked} style={{ float: 'right', marginRight: '120px', marginLeft: '10px' }} />
            <DefaultButton text="Back" disabled={this.state.backButtonDisabled} onClick={this.props.backClicked} style={{ float: 'right' }} />
          </div>
        </div>
      </div>
    );
  }
}

class Help extends Component {
  static displayName = Help.name;

  getHelpContent() {
    switch (VisioImporter.visibleComponent) {
      case "FileUpload":
        return (
          <div className="help-text">
            <p>
              Simply drag .vsdx files into the framed area. If your file is not displayed
              please check if the extension corresponds to ".vsdx". ".vsd" files must be converted.
              (Open in Visio, then save using "Save as" with the correct extension).
              Other file extensions are not supported.
            </p>
            <h5>Treat Visio shapes in different files, but with the same name, as one shape.</h5>
            <p>
              If you want to import several similar visual files at the same time, it may be useful to treat shapes from
              different files, but with the same name, as one shape, so that there is less effort in mapping.<br />.
              Uncheck this option if you want to map each shape type of each file.
            </p>
            <h5>Suggest a mapping based on the stencil sets used in Visio.</h5>
            <p>
              If you use shapes from a standard Visio stencil set, the importer can suggest a mapping.<br />
              Currently suggestions for the following sets are provided: BPMN, BASFLO.
            </p>
            <h5>Insert a Task in case a gateway contains a description.</h5>
            <p>
              In Symbio, gateway shapes do not have a directly visible description. The importer inserts descriptions
              as a task above the Gateway if this option is selected.
            </p>
            <h5>Divide groups with subgroups during the import.</h5>
            <p>
              Sometimes several group shapes are merged under one parent group shape in Visio.<br />
              If this option is selected, groups consisting of subgroups are decomposed into these subgroups during the import.
            </p>
            <h5>Re-use elements (e.g. Roles) already existing in Symbio.</h5>
            <p>
              Do not create a new element if an element with the same name and type already exists in Symbio.
              This only concerns related elements like roles or risks. Tasks, gateways, etc. are never re-used.
            </p>
          </div>
        );
      case "Mapping":
        return (
          <div className="help-text">
            <p>
              To connect two shapes, simply press the respective green button right next to the shape, one after the other.
              This draws a line between the two, so you can see what is already connected.
              A Visio shape can have only one connection, whereas a Symbio shape can be connected to multiple Visio shapes.
              If you want to remove a connection, click the small 'x' next to the green button.
            </p>
            <h4>The "Gateway" shape without And, Or and Xor in Symbio</h4>
            <p>
              If you use the standard BPMN gateways in Visio, you should map the gateway shape to the generic Symbio gateway and not directly to And, Or or Xor.
              The importer will then automatically detect the gateway. If no BPMN gateway was used in Visio, please map directly to And, Or or Xor.
            </p>
            <h4>The "No Master Shape"</h4>
            <p>
              Sometimes shapes in Visio do not have a master shape and therefore do not appear in the list. If this is the case, you can still use the "No Master Shape" option to
              map these shapes. In most cases you can ignore the "No Master Shape".
            </p>
          </div>
        );
      case "Preview":
        return (
          <div className="help-text">
            <p>Click on one of the displayed names to preview the sub-process.</p>
            <h4>Some connections are not displayed</h4>
            <p>
              Check again carefully if the shapes are also connected in Visio.
              It's best to move the shape once and see if the connections move as well.
            </p>
            <h4>There are some round shapes with numbers</h4>
            <p>
              This is an indication that not all the necessary shapes have been mapped. Go back to the mapping again and check
              whether all shapes are connected. If they are, try connecting the "No Master" shape. Some shapes in Visio
              do not have a master shape and therefore do not appear in the mapping overview.
            </p>
          </div>
        );
      case "Import":
        return (
          <div className="help-text">
            <p>Enter your import token. If you do not have a token please contact your Symbio administrator.</p>
            <h4>Language selection</h4>
            <p>
              After the token is submitted, a list of languages available in Symbio is displayed, from which at least one language must be selected.
              For languages not selected, Symbio will insert the default names (e.g. "Task") for that language view.
            </p>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    let content = this.getHelpContent();
    if (content == null)
      return (<div />);

    return (
      <Modal
        isOpen={true}
        onDismiss={this.props.helpClosed}
        isBlocking={false}
        styles={{ main: { maxWidth: "900px" } }}
      >
        <IconButton
          styles={{ root: { float: "right" } }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Hilfe schließen"
          onClick={this.props.helpClosed}
        />
        <h1 style={{ textAlign: 'center', marginTop: '30px' }}>Help</h1>
        {content}
      </Modal>
    );
  }
}

class Reset extends Component {
  static displayName = Reset.name;

  render() {
    return (<Dialog
      hidden={false}
      onDismiss={this.props.resetAborted}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Confirm reset",
        closeButtonAriaLabel: "Close",
        subText: "Are you sure you want to discard all modified mappings, as well as remove the uploaded visio files?",
        styles: { subText: { color: "black" } }
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 400 } },
      }}
    >
      <DialogFooter>
        <DefaultButton onClick={this.props.resetAborted} text="Abort" />
        <PrimaryButton onClick={this.props.resetConfirmed} text="Confirm" style={{ marginRight: '30px' }} />
      </DialogFooter>
    </Dialog>
    );
  }
}
